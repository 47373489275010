import { TablePagination, TableSortLabel } from '@mui/material';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import * as React from 'react';

export default function useModifiableParams({
  defaults = {},
  defaultOrder,
}: {
  defaults?: { [key: string]: string };
  defaultOrder?: [string, 'asc' | 'desc'];
} = {}) {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams: any = useSearchParams();

  const getParams = React.useCallback((): {
    [key: string]: any;
  } => {
    const params: { [key: string]: any } = {};
    for (const [key, value] of searchParams.entries()) {
      if (value === '') continue;

      params[key] = value;

      if (key === 'page') {
        params.page = parseInt(params.page, 10);
      }
    }

    if (defaultOrder) {
      if (![...searchParams.keys()].some((k) => k.startsWith('order['))) {
        params[`order[${defaultOrder[0]}]`] = defaultOrder[1];
      }
    }
    const merged = {
      ...(defaults ?? {}),
      ...params,
    };
    return merged;
  }, [defaults, defaultOrder, searchParams]);

  const modifiedQuerystring = React.useCallback(
    (modifications: { [key: string]: string | null }) => {
      const params = new URLSearchParams(searchParams as any);

      // Only 1 order param at a time
      if (Object.keys(modifications).some((key) => key.startsWith('order['))) {
        params.forEach((_, key) => {
          if (key.startsWith('order[')) {
            params.delete(key);
          }
        });
      }

      for (const [key, value] of Object.entries(modifications)) {
        if (value === null) {
          params.delete(key);
        } else {
          params.set(key, value);
        }
      }

      return params.toString();
    },
    [searchParams]
  );

  const modifiedLink = React.useCallback(
    (modifications: { [key: string]: string | null }) => {
      return `${pathname}?${modifiedQuerystring(modifications)}`;
    },
    [pathname, modifiedQuerystring]
  );

  const tablePaginationProps = React.useCallback(
    ({
      totalItems,
      rowsPerPage,
    }: {
      totalItems: number | undefined;
      rowsPerPage: number;
    }): React.ComponentProps<typeof TablePagination> => {
      const params = getParams();
      return {
        // Fallback for server endpoints with paginationPartial
        count: totalItems ?? 9_999_999,
        onPageChange: (_ev, p) =>
          router.push(
            modifiedLink({
              page: (p + 1).toString(),
            }) as any
          ),
        page: (params.page ?? 1) - 1,
        rowsPerPage,
        rowsPerPageOptions: [],
      };
    },
    [getParams, modifiedLink, router]
  );

  const tableSortLabelProps = React.useCallback(
    (
      param: string,
      defaultDir: 'asc' | 'desc' = 'asc'
    ): React.ComponentProps<typeof TableSortLabel> => {
      // We always want the arrow to follow the ascending order, which is opposite of MUI
      const reverseArrow = true;

      const fullParam = `order[${param}]`;
      const value = getParams()[fullParam];
      let arrowDir = value ?? defaultDir;
      if (reverseArrow) {
        arrowDir = arrowDir === 'asc' ? 'desc' : 'asc';
      }

      return {
        active: Boolean(value),
        direction: arrowDir,
        onClick: () => {
          const newValue =
            value === undefined ? defaultDir : value === 'asc' ? 'desc' : 'asc';
          router.push(
            modifiedLink({
              [fullParam]: newValue,
            }) as any
          );
        },
      };
    },
    [getParams, modifiedLink, router]
  );

  return {
    getParams,
    searchParams,
    modifiedLink,
    tablePaginationProps,
    tableSortLabelProps,
  };
}
