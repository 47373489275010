import { useAmplifyAuthContext } from '@/contexts';
import { useRouter } from 'next/navigation';
import React from 'react';

/**
 * add to beginning of pages which require user authentication
 */
export default function useAuthScreen() {
  const { amplifyUser } = useAmplifyAuthContext();
  const router = useRouter();

  React.useEffect(() => {
    console.log('amplifyUser', amplifyUser);
    if (amplifyUser === null) {
      router.replace('/login');
    }
  }, [amplifyUser, router]);
}
