'use client';

import { Container } from '@mui/material';
import NavBar from './NavBar';
import React from 'react';
import { useAuthScreen } from '@/hooks';

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  useAuthScreen();

  return (
    <Container maxWidth={false} disableGutters>
      <NavBar />
      {/* <FroloTopBar /> */}
      {children}
    </Container>
  );
}
