import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Menu01 } from '@untitled-ui/icons-react';
import { useAmplifyAuthContext } from '@/contexts';
import Image from 'next/image';
import config from '@/config';
import { Divider } from '@mui/material';
import { RouterLink } from '@mui-devias-kit/components/router-link';
import { usePathname, useRouter } from 'next/navigation';

const pages: Array<{ label: string; path: string }> = [
  { label: 'Users', path: '/users' },
];

/**
 * Standard MUI AppBar
 */
export default function NavBar() {
  const { signOut, amplifyUser } = useAmplifyAuthContext();
  const pathname = usePathname();
  const router = useRouter();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings: Array<{ label: string; onClick: () => void }> = [
    { label: 'Logout', onClick: signOut },
  ];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              width: 50,
              position: 'relative',
              height: 50,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Image
              src="/logo-new.png"
              fill
              objectFit="contain"
              alt="Frolo logo"
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <Menu01 />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.path}
                  onClick={() => router.push(page.path)}
                  selected={page.path === pathname}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Box
              sx={{
                width: 50,
                position: 'relative',
                height: 50,
              }}
            >
              <Image
                src="/logo-new.png"
                fill
                objectFit="contain"
                alt="Frolo logo"
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, pl: 2 }}>
            {pages.map((page) => {
              const active = page.path === pathname;
              return (
                <Button
                  key={page.path}
                  onClick={() => router.push(page.path)}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    ...(active && {
                      borderColor: 'ActiveBorder',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    }),
                  }}
                  LinkComponent={RouterLink}
                >
                  {page.label}
                </Button>
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={amplifyUser?.name}
                  src={`${config.IMAGES_BASE_URL}/${amplifyUser?.['custom:profile_image']}`}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box sx={{ paddingLeft: 3, paddingRight: 3 }}>
                <Typography textAlign="center" variant="overline" component="p">
                  {amplifyUser?.name}
                </Typography>
              </Box>
              <Divider sx={{ mt: 0.5 }} />
              {settings.map((setting) => (
                <MenuItem
                  key={setting.label}
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    justifyContent: 'center',
                  }}
                  onClick={setting.onClick}
                >
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
